@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
.TVChartContainer {
	/* height: calc(100vh - 80px); */
}

body{
	overflow-y: hidden;
	height: 100vh;
	/* color: yellow; */
}
* {
  margin: 0;
  padding: 0;
}
.panel-container {
  display: flex;
  /* height: 850px; */
}

.panel {
  /* background: blue; */
  border: 1px solid gray;
  /* padding: 15px; */
}
.panel:first-child {
}

.resizer {
  width: 8px;
  background: darkGray;
  position: relative;
  cursor: col-resize;
  flex-shrink: 0;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.resizer::after,
.resizer::before {
  content: "";
  border-left: 1px solid #333;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  right: 0;
  display: inline-block;
  height: 20px;
  margin: 0 2px;
}
.resizer::before {
  left: 0;
}

.TVChartContainer {
  height: 40vh;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/*
 *  STYLE 2
 */

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d62929;
}

/*
 *  STYLE 3
 */

#style-3::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  height: 7px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: grey;
}

#style-3 {
  scrollbar-color: grey #f5f5f5;
  scrollbar-width: thin;
}

#style-3-cust {
  scrollbar-color: grey grey;
  scrollbar-width: thin;
}
/*
 *  STYLE 3
 */

#style-3-cust::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #41454e;
}

#style-3-cust::-webkit-scrollbar {
  width: 6px;
  height: 7px;
  background-color: #f5f5f5;
}

#style-3-cust::-webkit-scrollbar-thumb {
}

/*
 *  STYLE 4
 */

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

/*
 *  STYLE 5
 */

#style-5::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar-thumb {
  background-color: #0ae;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

/*
 *  STYLE 6
 */

#style-6::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-6::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-6::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

/*
 *  STYLE 7
 */

#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

/*
 *  STYLE 8
 */

#style-8::-webkit-scrollbar-track {
  border: 1px solid black;
  background-color: #f5f5f5;
}

#style-8::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-8::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/*
 *  STYLE 9
 */

#style-9::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-9::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-9::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

/*
 *  STYLE 10
 */

#style-10::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-10::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

/*
 *  STYLE 11
 */

#style-11::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-11::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-11::-webkit-scrollbar-thumb {
  background-color: #3366ff;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
}

/*
 *  STYLE 12
 */

#style-12::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  background-color: #444444;
}

#style-12::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-12::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d62929;
  background-image: -webkit-linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.4) 50%,
    transparent,
    transparent
  );
}

/*
 *  STYLE 13
 */

#style-13::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  background-color: #cccccc;
}

#style-13::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-13::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d62929;
  background-image: -webkit-linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.4) 50%,
    transparent,
    transparent
  );
}

/*
 *  STYLE 14
 */

#style-14::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  background-color: #cccccc;
}

#style-14::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-14::-webkit-scrollbar-thumb {
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 25%,
    transparent 100%,
    rgba(0, 0, 0, 1) 75%,
    transparent
  );
}

/*
 *  STYLE 15
 */

#style-15::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-15::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-15::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4d9c41),
    to(#19911d),
    color-stop(0.6, #54de5d)
  );
}

/*
 *  STYLE 16
 */

#style-16::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-16::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-16::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    top,
    #e4f5fc 0%,
    #bfe8f9 50%,
    #9fd8ef 51%,
    #2ab0ed 100%
  );
}

th {
  /* white-space: nowrap; */
  /* text-align: center; */
  height: 53px !important;
  text-align: center;
  padding: 5px 12px !important;

  /* vertical-align: center; */
}
th div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  text-align: center;
}
td {
  /* white-space: nowrap; */

  text-align: center;
  /* color: #131721; */
}

.styles_overlay__CLSq- {
  z-index: 100000;
}

.styles_modal__gNwvD {
  padding: 0 !important;
  /* background-color: transparent !important; */
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #252e42;
  z-index: 100000000;
  /* background: white; */
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  /* background: rgba(55, 55, 55, 0.6); */
  z-index: 100000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  /* border-bottom: solid 2px #333; */
  /* border-right: solid 2px #333; */
}
.flexible-modal-drag-area {
  /* background: rgb(22, 22, 250); */
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.clr_change_hover:hover {
  background-color: rgb(217, 222, 223) !important;
  cursor: pointer;
  color: #000000 !important;
}

.tab-row {
  border-bottom: 1px solid rgb(175, 177, 190) !important;
  /* padding-top: 14px; */
}

.tab-col {
  padding-top: 14px;
  /* border-right: 1px solid #fff; */
}

.tab-col div.inner-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* background-color: blue; */
  /* align-items: center; */
}

.tab-col div.inner-col p {
  
  padding-bottom: 0px !important;
  margin-bottom: 0.3rem !important;
}

.clickable_scale {
  cursor: pointer;
  opacity: 0.5;
  -webkit-transition: all .25s;
  transition: all .25s;
  display: inline-block;
  /* transform: scale(1.5) !important; */
}

.clickable_scale:hover {
  opacity: 1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

body {
  display: flex;
  justify-content: center;
}

.vldform {
    box-sizing: border-box;
    max-width: 805px;
    display: flex;
    flex-direction: column;
    padding: 35px 55px;
    font-family: "Nunito Sans";
    -webkit-animation: a .5s;
            animation: a .5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    border: #d4d4d4 1px solid;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
}

.vldform a {
    text-decoration: none;
}

.vldform h1 {
    font-size: 40px;
    color: #552FAB;
    margin: 0px 0px 26px 0px;
}

.vldform__textbox {
    border: 0;
    outline: 0;
    border-bottom: 2px #512DA8 solid;
    font-size: 18px;
    margin-top: 36px;
    padding-bottom: 9px;
    font-family: "Nunito Sans";
}

.vldform__textbox[type="password"]::after {
    content: " ";
    display: block;
    width: 10px;
    height: 10px;
    background-color: rebeccapurple;
    
}

.vldform__recoverypassword{
    align-self: flex-end;
    margin: 10px 0px;
    font-size: 16px;
    color: #2E67A9;
}

.vldform__button {
    margin-top: 42px;
    height: 50px;
    border: 0;
    color: #fff;
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#512DA8), to(#673AB7));
    background-image: linear-gradient(#512DA8, #673AB7);
    font-size: 22px;
    font-weight: 600;
    font-family: "Nunito Sans";
    cursor: pointer;
}

.vldform__signup {
    align-self: center;
    margin-top: 50px;
    margin-bottom: 0px;
}

.vldform__signup a {
    color: #6538B5;
    font-weight: 600;
    margin-left: 4px;
}

.vldreg {
    display: none;
}

.vldrecpass {
    display: none;
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(5px);
                transform: translateY(5px)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(5px);
                transform: translateY(5px)
    }
}

@media (max-width: 400px) {
    .vldform {
        width: 350px;
        padding: 20px 40px;
    }
    
}

@media (max-width: 360px) {
    .vldform {
        width: 305px;
        padding: 20px 35px;
    }

    .vldform__signup {
        text-align: center;
    }

    .vldform__signup a {
        margin-left: 14px;
    }
}
